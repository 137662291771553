import axios from './axios';

class Api {
    authenticate = (accessToken) => {
      this.accessToken = accessToken;
    }

    _authHeader = () => {
      return { headers: {'Authorization': 'Bearer ' + this.accessToken} }
    }

    clients = {
      getAll: (search) => {
        let searchQuery = search ? `&name[$iLike]=${search}%` : '';

        return axios.get(
        '/api/clients',
        this._authHeader()
      )},
      getById: clientId => axios.get(
        `/api/clients/${clientId}`,
        this._authHeader()
      )
    }

    products = {
      getProductsByClientName: (clientName) => axios.get(
        `/api/topup/clients/${encodeURIComponent(clientName)}/inventories`,
        this._authHeader()
      )
    }

    registrations = {
      createRegistration: (products, clientId) => axios.post(
        `/api/topup/clients/${clientId}/inventories`,
        products,
        this._authHeader()
      )
    }
}

const api = new Api();

export default api;
