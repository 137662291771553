import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Subtitle, H4 } from './CustomTypography';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    centerContainer:{
        position: 'absolute',
        width: '80%',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    headline:{
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    text: {
        textAlign: 'center',
        marginBottom: 10
    },
    buttons: {
        textAlign: 'right'
    },
    buttonNo: {
        marginRight: '10px',
    }
});

class ConfirmationModal extends Component {
  state = {
    inputValue: ''
  }
  componentWillReceiveProps (nextProps){
    if(nextProps.text !== this.state.inputValue)
      {this.setState({
        inputValue: nextProps.text
      })}
  }

  
  render (){

    const {
      isOpen,
      handleOnClose
    } = this.props;


    return(
      <Modal
        aria-describedby='simple-modal-description'
        aria-labelledby='simple-modal-title'
        onClose={handleOnClose}
        open={isOpen}
      >
      {
        this.renderByVariant()
      }
    </Modal>)
  }

  renderByVariant = () => {
    const { 
        classes,
        headline,
        handleOnClose,
        variant 
    } = this.props;

    switch(variant) {
        case 'THANK-YOU-MODAL':
            return (
                <div className={classes.centerContainer}>
                    <H4 className={classes.headline}>
                        {headline}
                    </H4>
                </div>
            )
        default:
                return (
                    <div className={`${classes.paper} ${classes.centerContainer}`}>
                    <Subtitle>
                        {headline}
                    </Subtitle>
                    <TextField
                    fullWidth
                    margin='normal'
                    multiline
                    onChange={this.handleChange}
                    value={this.state.inputValue}
                    />
                    <div className={classes.buttons}>
                        <Button
                            className={classes.buttonNo}
                            color='primary'
                            onClick={handleOnClose}
                            variant='contained'
                        >
                            Cancel
                        </Button>
                        <Button
                            color='primary'
                            onClick={this.handleOnConfirm}
                            variant='contained'
                        >
                            Save
                        </Button>
                    </div>
                </div>
                )
        
  }
}

  handleOnConfirm = () => {
    this.props.handleOnConfirm(this.state.inputValue)
  }

  handleChange = (e) => {
    this.setState({
      inputValue: e.target.value
    })
  }
}

export default withStyles(styles)(ConfirmationModal);
