import { authActions } from './actions';

const initialState = {
  loading: true,
  token: null,
  email: null,
  error: null,
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
      case authActions.AUTH_START:
        return { ...state, loading: true, error: null };
      case authActions.AUTH_ERROR:
        return { ...state, loading: false, error: action.payload };
      case authActions.AUTH_LOGIN_SUCCESS:
        const { token, email } = action.payload;
        return { ...state, loading: false, error: null, token, email }
      case authActions.AUTH_LOGOUT:
        return {...initialState, loading: false};
      default:
        return state;
    }
};


export default reducer;
