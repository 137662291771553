import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadClientById } from '../../store/clients/actions';
import Spinner from '../../components/UI/Spinner';
import { Subtitle, Heading } from '../../components/UI/CustomTypography';
import '../../style/Clients.css';
import Create from '@material-ui/icons/Create';
import history from '../../shared/history';


const MOCK_DATA = [
  {
    id: 1,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 2,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 3,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 4,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 5,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 6,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 7,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 8,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 9,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 10,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 11,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 12,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 13,
    date: '29.SEPTEMBER 18'
  },
  {
    id: 14,
    date: '29.SEPTEMBER 18'
  },
]

const renderRegistrationItem = ({ id, name, date, onClick, isCreate }) => (
  <div className={'Registration-ListItem' + (isCreate? ' create': '')} key={id} onClick={onClick}>
    <Subtitle>
      {name}{!isCreate && `, ${date}`}
    </Subtitle>
    <Create className='icon' />
  </div>
)

class ClientDetails extends Component {
  componentDidMount (){
    const { match: { params:  { clientId } } } = this.props;
    this.props.loadClientById(clientId)
  }



  render (){
    const { loading, client: { name } } = this.props;
    if(loading)
      {return <Spinner />}
    return(
      <div className='Page'>
        <Heading title={`${name} REGISTRATIONS`} />
        <div>
          {
            renderRegistrationItem({ id: 0, name: 'ADD TODAY\'S REGISTRATIONS', onClick: this.onAddItemClick , isCreate: true })
          }
        </div>
        <div className='Page-Scroll'>
          {
            MOCK_DATA.map(item => renderRegistrationItem({...item, name, onClick: this.onRegistrationItemClick(item.id)}))
          }
        </div>
      </div>
    )
  }

  onAddItemClick = () => {
    const { match: { params:  { clientId } } } = this.props;
    history.push(`/clients/${clientId}/registrations/create`);
  }

  onRegistrationItemClick = (registrationId) => () => {
    const { match: { params:  { clientId } } } = this.props;
    history.push(`/clients/${clientId}/registrations/${registrationId}`)
  }

}

const mapStateToProps = ({ clients: { client, loading, error } }) => ({
  client,
  loading,
  error
})

export default connect(mapStateToProps, { loadClientById })(ClientDetails)
