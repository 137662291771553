import Api from '../../Api';
import moment from 'moment';

export const registrationActions = {
  REGISTRATION_START: 'REGISTRATION_START',
  REGISTRATION_ERROR: 'REGISTRATION_ERROR',
  CREATE_REGISTRATION_SUCCESS: 'CREATE_REGISTRATION_SUCCESS',
  INIT_REGISTRATION: 'INIT_REGISTRATION'
}

const registrationStart = () => {
  return {
    type: registrationActions.REGISTRATION_START
  }
}

const registrationError = (err) => {
  return {
    type: registrationActions.REGISTRATION_ERROR,
    payload: err
  }
}

export const initRegistration = () => dispatch => {
  dispatch({
    type: registrationActions.INIT_REGISTRATION
  })
}

export const onCreateRegistration = (payload) => async dispatch => {
  const {products, client} = payload
    const date = moment().format('YYYY-MM-DD');
    const filteredProducts = products.filter( item => item.amount && item.amount !== " " && Number(item.amount.replace(',', '.')) >= 0 );
    const preparedProducts = filteredProducts.map( item => ({
            productId: item.id,
            level: Number(item.amount.replace(',', '.')),
            date
       })
    );

    dispatch(registrationStart());
    try {
        await Api.registrations.createRegistration(preparedProducts, client.id);
        dispatch({
            type: registrationActions.CREATE_REGISTRATION_SUCCESS
        });
    } catch(error){
        dispatch(registrationError(error));
    }
}
