import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import { connect } from 'react-redux';
import { validateAuthToken } from './store/auth/actions';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Login from './containers/Login';
import ClientList from './containers/Client/ClientList';
import ClientDetails from './containers/Client/ClientDetails';
import RegistrationCreate from './containers/Registration/RegistrationCreate';
import RegistrationDetail from './containers/Registration/RegistrationDetail';

class App extends Component {
  componentDidMount (){
    this.props.validateAuthToken();
  }
  render () {
    const { loading } = this.props;
    return (
      <Layout>
        {!loading && this.renderRoutes()}
      </Layout>
    );
  }

  renderRoutes = () => {
    let routes = (
      <Switch>
        <Route component={Login} exact path='/' />
        <Redirect to='/' />
      </Switch>
    );

    if(this.props.isAuthenticated){
      routes = (
        <Switch>
          <Route component={RegistrationCreate} path='/clients/:clientId/registrations/create' />
          <Route component={RegistrationDetail} path='/clients/:clientId/registrations/:registrationId' />
          <Route component={ClientDetails} path='/clients/:clientId/registrations' />
          <Route component={ClientList} exact path='/' />
          <Redirect to='/' />
        </Switch>
      )
    }

    return routes;
  }
}

const mapStateToProps = ({ auth: { loading, error, token, email, isTokenValidated } }) =>(
  {
    isAuthenticated: token != null,
    email,
    loading,
    error
  }
);

export default withRouter(connect(mapStateToProps, { validateAuthToken })(App));
