import React from 'react';
import Typography from '@material-ui/core/Typography';


export const H5 = (props) => (
  <Typography {...props} variant='h5' />
)

export const Subtitle = (props) => (
  <Typography {...props} variant='subtitle1' />
)

export const Heading = ({ title }) => (
  <div className='Heading'>
    <H5>{ title }</H5>
  </div>
)

export const Text = (props) => (
  <Typography {...props} variant='body1' />
)

export const H4 = (props) => (
  <Typography {...props} variant='h4' />
)
