import { clientActions } from './actions';

const initialState = {
  loading: false,
  error: null,
  clients: [],
  client: {}
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
      case clientActions.CLIENTS_START:
        return { ...state, loading: true, error: null };
      case clientActions.CLIENTS_ERROR:
        return { ...state, loading: false, error: action.payload };
      case clientActions.GET_CLIENTS_SUCCESS:
        return { ...state, loading: false, error: null, clients: action.payload }
      case clientActions.LOAD_CLIENT_SUCCESS:
        return { ...state, loading: false, error: null, client: action.payload }
      default:
        return state;
    }
};


export default reducer;
