import Api from '../../Api';
import { loadClientById } from '../clients/actions';

export const productActions = {
  PRODUCTS_START: 'PRODUCTS_START',
  PRODUCTS_ERROR: 'PRODUCTS_ERROR',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  PRODUCT_VALUE_CHANGE: 'PRODUCT_VALUE_CHANGE'
}

const productsStart = () => {
  return {
    type: productActions.PRODUCTS_START
  }
}

const productsError = (err) => {
  return {
    type: productActions.PRODUCTS_ERROR,
    payload: err
  }
}

export const getProductsByClientName = (clientId) => async (dispatch, getState) => {
  dispatch(productsStart());
  try {
    const { clients: { client } } = getState();
    if(client.id !== clientId){
      await dispatch(loadClientById(clientId))
    }
    const clientName = getState().clients.client.id;
    const products = (await Api.products.getProductsByClientName(clientName)).data;
    dispatch({
      type: productActions.GET_PRODUCTS_SUCCESS,
      payload: products
    })
  } catch (e) {
    dispatch(productsError(e));
  }
}

export const onProductChange = (product) => dispatch => {
  dispatch({
    type: productActions.PRODUCT_VALUE_CHANGE,
    payload: product
  })
}
