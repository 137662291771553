import React, { Component } from 'react'
import { Text } from '../../components/UI/CustomTypography';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import { connect } from 'react-redux';
import { onProductChange } from '../../store/products/actions';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../UI/Modal';

const styles = {
  purpleText: {
    color: '#5967f1'
  }
}

class RegistrationRow extends Component {
  state = {
    isFocused: false,
    modalOpened: false
  }

  render (){
    const {
      item,
      classes
    } = this.props;
    const { isFocused, modalOpened } = this.state;
    return (
      <div className='Registration-row'>
        <Modal
          handleOnClose={this.onModalClose}
          handleOnConfirm={this.onNoteChange}
          headline={item.name}
          isOpen={modalOpened}
          text={item.note || ''}
        />
        <Text className='Registration-item-fill Registration-item-name'>
          {item.name}<br/>
          <span className='item-note'>{item.note}</span>
        </Text>
        {
          !item.amount && !isFocused?
            <Text
              className='Registration-item-20pc text-center'
              onClick={this.handleOnAddClick}
            >
              {/* <CreateIcon className={classes.purpleText} /> */}
            </Text>
            :
            <Text className='Registration-item-20pc text-center'>
              {/* <ChatBubbleIcon
                className={classes.purpleText}
                onClick={this.handleOnOpenModal}
              /> */}
            </Text>
        }
        {
          !item.amount && !isFocused && !item.edited ? 
            <Text
              className={`Registration-item-20pc ${ classes.purpleText }`}
              onClick={this.handleOnAddClick}
            >Add</Text>
            :
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              className={`Registration-item-20pc ${ classes.purpleText }`}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnAmountChange}
              type='tel'
              value={item.amount ? item.amount.trim() : ''}
            />


        }
      </div>
    )
  }

  onNoteChange = (value) => {
    const product = Object.assign({}, this.props.item);
    product.note = value;
    this.props.onProductChange(product);
    this.onModalClose();
  }

  onModalClose = () => {
    this.setState({
      modalOpened: false
    });
  }

  handleOnAddClick = () => {
    const product = Object.assign({}, this.props.item);
    //product.note = '';
    //product.amount = '0';
    product.edited = true;
    this.props.onProductChange(product);
  }

  handleOnAmountChange = (e) => {
    const { target: { value } } = e;
    const product = Object.assign({}, this.props.item);
    if(!this.state.isFocused)
      {this.setState({ isFocused: true })}

    const isNumber = /^[0-9]+,{0,1}[0-9]*$/g;
    if(value.length === 0){
      product.amount = ' ';
    }
    else if(value.match(isNumber)){
      product.amount = value
    }
    this.props.onProductChange(product);
  }

  handleOnOpenModal = () => {
    this.setState({
      modalOpened: true,
    })
  }

  handleOnBlur = () => {
    const { item } = this.props;
    this.setState({ isFocused: false })
    if(item.amount === ' '){
      this.props.onProductChange({ ...item, amount: '' });
    }
  }

}

export default connect(null, { onProductChange })(withStyles(styles)(RegistrationRow))
