import React from 'react';
import ReactDOM from 'react-dom';
import './style/manifest.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import history from './shared/history';
import 'typeface-roboto';

import authReducer from './store/auth/reducer';
import clientsReducer from './store/clients/reducer';
import productReducer from './store/products/reducer';
import registrationReducer from './store/registrations/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  products: productReducer,
  registrations: registrationReducer
})

const store = createStore(rootReducer,
    compose(
        applyMiddleware(thunk)
    )
);

const app = (
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
