import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Spinner from '../../components/UI/Spinner';
import { connect } from 'react-redux';
import { onLogin } from '../../store/auth/actions';
import '../../style/Login.css';
class Login extends Component {
  state={
    email: '',
    password: ''
  }

  render (){
    const { loading, error } = this.props;
    if(loading) {return (<Spinner />)}
    return (
      <form className='Login' onSubmit={this.handleSubmit}>
        <FormControl>
          { error && <span style={{ color: 'red' }}>Could not sign in!</span>}
          <TextField
            label='Email' name='email' onChange={this.handleInputOnChange}
            type='email'
          />
          <TextField
            label='Password' name='password' onChange={this.handleInputOnChange}
            type='password'
          />
          <Button
            color='primary'
            style={{ marginTop: 35 }}
            type='submit'
            variant='contained'
          >
            Login
          </Button>
        </FormControl>
      </form>
    )
  }

  handleInputOnChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.onLogin(email, password);
  }
}

const mapStateToProps = ({auth: { loading, error }}) => (
  {
    loading, error
  }
)

export default connect(mapStateToProps, { onLogin })(Login);
