import Api from '../../Api';

export const clientActions = {
  CLIENTS_START: 'CLIENTS_START',
  CLIENTS_ERROR: 'CLIENTS_ERROR',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  LOAD_CLIENT_SUCCESS: 'LOAD_CLIENT_SUCCESS'
}

const clientsStart = () => {
  return {
    type: clientActions.CLIENTS_START
  }
}

const clientsError = (err) => {
  return {
    type: clientActions.CLIENTS_ERROR,
    payload: err
  }
}

export const getClients = (search) => async dispatch => {
  dispatch(clientsStart())
  try {
    const clients = await Api.clients.getAll(search);
    dispatch({
      type: clientActions.GET_CLIENTS_SUCCESS,
      payload: clients.data
    });
  } catch (e) {
    dispatch(clientsError(e))
  }
}

export const loadClientById = clientId => async dispatch => {
  dispatch(clientsStart());
  try {
    const client = await Api.clients.getById(clientId)
    dispatch({
      type: clientActions.LOAD_CLIENT_SUCCESS,
      payload: client.data
    })
  } catch (e) {
    dispatch(clientsError(e))
  }
}
