import axios from 'axios';

let baseURL = '__API_ENDPOINT_PLACEHOLDER__'

if(process.env.NODE_ENV === 'development') {
    baseURL = 'https://api.conval.goodmonday.io/';
}



const instance = axios.create({
    baseURL
});

export default instance;
