import React from 'react'
import Header from '../Header';

const layout = ({children}) => (
  <div className='layout'>
    <Header />
    {children}
  </div>
)

export default layout
