import React, { Component } from 'react';
import { Heading } from '../../components/UI/CustomTypography';


class RegistrationDetail extends Component {
  render (){
    return(
      <div>
        <Heading title={'[NAME] REGISTRATIONS'} />
        DETAIL
      </div>
    )
  }
}


export default RegistrationDetail;
