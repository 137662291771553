import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getClients } from '../../store/clients/actions';
import { H5, Heading } from '../../components/UI/CustomTypography';
import '../../style/Clients.css';
import history from '../../shared/history';

const renderCustomerItem = ( { name, id, address } ) => (
  <div
    className='Client-ListItem'
    key={id}
    onClick={() => history.push(`/clients/${id}/registrations/create`)}
  >
      <H5 gutterBottom style={{ fontWeight: 'bold' }}>{ name }</H5>
      <p>{ address.addressLine } { address.zipcode }</p>
  </div>
)

class ClientList extends Component {
  componentDidMount (){
    this.props.getClients();
  }


  constructor() {
    super();
    this.state = {filter: ''};
  }

  filteredClients(clients) {
    const filtered =  clients
      .filter(client => {
        return client.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1;
      })

      return filtered
  }

  handleChange = (event) => {
    this.setState({filter: event.target.value})
  }

  render (){
    return (
      <div className='Page'>
        <Heading title="SEARCH BY CLIENT NAME" />

        <div className='Page-Scroll'>
          <input
            className='SearchBox'
            type='text'
            onChange={this.handleChange}/>
          { this.filteredClients(this.props.clients).map( item => renderCustomerItem(item)) }
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({clients: { loading, error, clients }}) => (
  {
    loading, error, clients
  }
)

export default connect(mapStateToProps, { getClients })(ClientList);
