import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const spinner = () => (
  <div style={{ margin: 'auto', padding: '55px', textAlign: 'center' }}>
    <CircularProgress size={70} />
  </div>
);

export default spinner;
