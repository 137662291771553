import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onLogout } from '../../store/auth/actions';
import history from '../../shared/history';

const goBack = () => {
  history.push('/')
};

const Header = (props) => {
  if(!props.isAuthenticated){return null;}
  return (
    <div className='Header'>
      {
        props.location.pathname === '/'?
          <Button
            onClick={props.onLogout}
            variant='outlined'
          >
            Logout
          </Button>
          :
          <Button
            onClick={goBack}
          >
            <ArrowBackIos /> Back
          </Button>
      }
    </div>
  )
}

const mapStateToProps = ({ auth: { token } }) =>(
  {
    isAuthenticated: token != null
  }
)

export default withRouter(connect(mapStateToProps, { onLogout })(Header));
