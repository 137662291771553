import { registrationActions } from './actions';

const initialState = {
  loading: false,
  error: null,
  registration: {},
  isCreated: false
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
      case registrationActions.REGISTRATION_START:
        return { ...state, loading: true, error: null, isCreated: false };
      case registrationActions.REGISTRATION_ERROR:
        return { ...state, loading: false, error: action.payload, isCreated: false };
      case registrationActions.CREATE_REGISTRATION_SUCCESS:
        return { ...state, loading: false, error: null, isCreated: true }
      case registrationActions.INIT_REGISTRATION:
        return { ...state, loading: false, error: null, isCreated: false }
      default:
        return state;
    }
};


export default reducer;
